import * as React from 'react'
import { styled } from '@mui/system'
import { Link } from 'gatsby'

import Logo from 'images/large-logo.svg'

const StyledImg = styled('img')({
  display: 'block',
})

const NavbarLogo = () => (
  <Link to="/" data-track-gtm="Marketing Site|Top Navigation|Homepage">
    <StyledImg src={Logo} alt="sway.dm logo" />
  </Link>
)

export default NavbarLogo

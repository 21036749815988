import { Drawer, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import * as React from 'react'

import Button from 'components/button'

const ClickText = ({ ...props }) => (
  <Typography
    color="primary"
    fontWeight={800}
    fontSize="32px"
    sx={{ cursor: 'pointer' }}
    textAlign="center"
    pt={2}
    {...props}
  />
)
const StyledButton = styled(Button)({
  width: '100%',
  padding: '12px 24px',
})
const UnstyledLink = styled(Link)`
  text-decoration: none;
`
const UnstyledA = styled('a')`
  text-decoration: none;
`

const closeAndScroll = ({ onClose, elementId, afterScroll }) => {
  onClose()

  const element = document.getElementById(elementId)
  if (element) {
    // The drawer saves scroll position which would normally be good, but to get
    // around it here we close the drawer first and use the 1ms timeout trick
    // (_.defer) to do our scrolling last
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth' })
      if (afterScroll) {
        afterScroll()
      }
    }, 1)
  } else {
    navigate(`/#${elementId}`)
  }
}

const MobileDrawer = ({ onClose, ...other }) => {
  return (
    <Drawer anchor="right" onClose={onClose} {...other}>
      <Stack alignItems="center" direction="column" p={3}>
        <ClickText
          onClick={() =>
            closeAndScroll({ onClose, elementId: 'how-sway-works' })
          }
          data-track-gtm="Marketing Site|Top Navigation|How it works"
        >
          How it works
        </ClickText>
        <UnstyledLink to="/about-us" tabIndex={-1}>
          <ClickText data-track-gtm="Marketing Site|Top Navigation|About Us">
            About Us
          </ClickText>
        </UnstyledLink>
        <UnstyledA href="/blog" tabIndex={-1}>
          <ClickText data-track-gtm="Marketing Site|Top Navigation|Blog">
            Blog
          </ClickText>
        </UnstyledA>
      </Stack>
      <Stack alignItems="center" direction="column" m={2}>
        <StyledButton
          variant="outlined"
          size="large"
          href="/login"
          sx={{ mt: 2 }}
        >
          Log In
        </StyledButton>
      </Stack>
    </Drawer>
  )
}

MobileDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default MobileDrawer

import * as React from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Helmet } from 'react-helmet'

import 'styles/global.css'
import SkipToContent from './skip-to-content'
import { brandPurple } from 'shared/styles'

import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect } from 'react'
import { useLocation } from '@reach/router'
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

export const analytics = AnalyticsBrowser.load({
  writeKey: 'BBu3BJkm5Uxj1tDGHj1jqqgw8PWrsDvb',
})

const theme = createTheme({
  typography: {
    fontFamily: `'nunito sans', sans-serif`,
    allVariants: {
      color: '#000065',
    },
  },
  palette: {
    primary: {
      main: brandPurple,
    },
    light: {
      main: '#FFFFFF',
      dark: grey[200],
    },
    navy: {
      main: '#000065',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
})

const Layout = ({ location, contentId, children }) => {
  useEffect(() => {
    if (location) {
      analytics.page({
        name: location.pathname + location.hash,
        path: location.pathname + location.hash,
        properties: location,
      })
    }
  }, [location])
  return (
    <>
      <Helmet>
        <html lang="en" />
        <link
          rel="preload stylesheet"
          href="https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap"
          as="style"
        />
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://sway.dm/info" />
        <meta property="og:title" content="SwayDM | Community Rewards Marketplace" />
        <meta
          property="og:description"
          content="Redeem SwayCash for rewards or donate to local charities!"
        />
        <meta
          property="og:image"
          content="https://sway-dm-prod.s3.amazonaws.com/images/social-preview.png"
        />

        <title>SwayDM | Community Rewards Marketplace</title>
        <meta
          name="description"
          content="Redeem SwayCash for rewards or donate to local charities!"
        />
        <link rel="canonical" href="https://sway.dm/info/" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <SkipToContent contentId={contentId} />
        {children}
      </ThemeProvider>
      <CookieConsent
        cookieName="gatsby-gdpr-google-tagmanager"
        buttonText="Accept all cookies"
        declineButtonText="Necessary cookies only"
        flipButtons
        enableDeclineButton
        buttonStyle={{
          background: '#9400F6',
          color: 'white',
          borderRadious: '4px',
        }}
        declineButtonStyle={{
          background: '#9400F6',
          color: 'white',
        }}
        onAccept={() => {
          initializeAndTrack(location)
        }}
      >
        By clicking “Accept all cookies”, you agree SwayDM can store cookies on
        your device and disclose information in accordance with our{' '}
        <a style={{ color: 'white', padding: '5px' }} href="/cookies">
          Cookie Policy
        </a>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
  contentId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout

import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

const SwayButton = ({ sx, ...props }) => (
  <Button sx={{ textTransform: 'none', ...sx }} {...props} />
)

SwayButton.propTypes = {
  sx: PropTypes.object,
}

export default SwayButton

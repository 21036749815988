import * as React from 'react'
import { Toolbar } from '@mui/material'
import { styled } from '@mui/system'

import Content from 'components/content'
import FullSizeNavbar from './full-size-navbar'
import MobileNavbar from './mobile-navbar'

const FullSizeNavbarContainer = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down(1015)]: {
    display: 'none',
  },
}))
const MobileNavbarContainer = styled(Toolbar)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down(1015)]: {
    display: 'flex',
  },
}))

const NavBar = () => (
  <nav>
    <Content
      noPadding
      sx={{
        backgroundColor: 'white',
        position: 'static',
      }}
    >
      <FullSizeNavbarContainer>
        <FullSizeNavbar />
      </FullSizeNavbarContainer>
      <MobileNavbarContainer>
        <MobileNavbar />
      </MobileNavbarContainer>
    </Content>
  </nav>
)

export default NavBar

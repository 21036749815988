import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/system'

const StyledA = styled('a')({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0,

  '&:focus': {
    width: 'auto',
    height: 'auto',
    padding: '0',
    margin: '0',
    overflow: 'visible',
    clip: 'auto',
    whiteSpace: 'normal',
  },
})

const SkipToContent = ({ contentId }) => (
  <StyledA tabIndex="0" href={`#${contentId}`}>
    Skip to content
  </StyledA>
)

SkipToContent.propTypes = {
  contentId: PropTypes.string,
}

export default SkipToContent

import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Button from 'components/button'
import { Link, navigate } from 'gatsby'
import * as React from 'react'

import Logo from './logo'

const ClickText = ({ ...props }) => (
  <Typography color="primary" fontWeight={600} tabIndex={-1} {...props} />
)
const NavButton = ({ ...props }) => <Button size="large" {...props} />
const UnstyledLink = styled(Link)`
  text-decoration: none;
`
const UnstyledA = styled('a')`
  text-decoration: none;
`

const FullSizeNavbar = () => (
  <>
    <Logo />
    <Box sx={{ flexGrow: 1 }} />
    <Stack spacing={2} direction="row" mr={3}>
      <NavButton
        variant="text"
        onClick={() => {
          const howWorksSection = document.getElementById(
            'how-sway-works-section'
          )
          if (howWorksSection) {
            howWorksSection.scrollIntoView({ behavior: 'smooth' })
          } else {
            navigate('/#how-sway-works')
          }
        }}
        data-track-gtm="Marketing Site|Top Navigation|How it works"
      >
        <ClickText>How it works</ClickText>
      </NavButton>
      <UnstyledLink to="/about-us" tabIndex={-1}>
        <NavButton
          variant="text"
          data-track-gtm="Marketing Site|Top Navigation|About Us"
        >
          <ClickText>About Us</ClickText>
        </NavButton>
      </UnstyledLink>
      <UnstyledA href="/blog" tabIndex={-1}>
        <NavButton
          variant="text"
          data-track-gtm="Marketing Site|Top Navigation|Blog"
        >
          <ClickText>Blog</ClickText>
        </NavButton>
      </UnstyledA>
    </Stack>
    <Stack spacing={2} direction="row">
      <NavButton
        variant="outlined"
        href="/login"
        data-track-gtm="Marketing Site|Top Navigation|Login"
      >
        Log In
      </NavButton>
    </Stack>
  </>
)

export default FullSizeNavbar

import * as React from 'react'
import { Box, IconButton } from '@mui/material'

import Logo from './logo'
import Hamburger from 'images/hamburger.svg'
import Drawer from './drawer'

const MobileNavbar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  return (
    <>
      <Logo />
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen(true)}
      >
        <img src={Hamburger} alt="mobile hamburger menu icon" />
      </IconButton>
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
    </>
  )
}
export default MobileNavbar

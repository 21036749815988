import * as React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const Content = ({ sx, noPadding, ...props }) => {
  const paddingCss = noPadding
    ? {}
    : {
        boxSizing: 'border-box',
        padding: {
          xs: '48px 32px',
          lg: '100px',
        },
      }

  return (
    <Box
      sx={{
        maxWidth: '1440px',
        margin: '0 auto',
        ...paddingCss,
        ...sx,
      }}
      {...props}
    />
  )
}

Content.propTypes = {
  sx: PropTypes.object,
  noPadding: PropTypes.bool,
}

export default Content
